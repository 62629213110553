<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <organization-selector
        :organization="warehouse.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            warehouse.organization.id = organizationId;
            warehouse.warehouseable.id = null;
            onFormChanged();
          }
        "
        :disabled="!!warehouse.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="warehouse.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="warehouse.organization.id"
        @locationsChanged="
          (locations) => {
            warehouse.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      v-if="warehouse.organization.id && !hideWarehouseable"
      :label="`${$t('WAREHOUSES.WAREHOUSE_TYPE')} (*)`"
      :placeholder="$t('WAREHOUSES.WAREHOUSE_TYPE')"
    >
      <el-select
        :name="`${$t('WAREHOUSES.WAREHOUSE_TYPE')}`"
        :placeholder="$t('WAREHOUSES.WAREHOUSE_TYPE')"
        v-model="warehouse.warehouseable.type"
        @onchange="
          () => {
            onFormChanged();
          }
        "
        :disabled="!!warehouse.id"
      >
        <el-option value="customers" :label="$t(`COMMON.CUSTOMER`)" />
        <el-option value="suppliers" :label="$t(`COMMON.SUPPLIER`)" />
      </el-select>
    </base-input>

    <div v-if="warehouse.warehouseable.type">
      <!-- Supplier -->
      <div
        v-if="
          warehouse.warehouseable.type === 'suppliers' && !hideWarehouseable
        "
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.SUPPLIER')} (*)`"
            :placeholder="$t('COMMON.SUPPLIER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
          >
            <supplier-selector
              :supplier="warehouse.warehouseable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="warehouse.organization.id"
              @supplierChanged="
                (supplierId) => {
                  warehouse.warehouseable.id = supplierId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.warehouseable" />
        </div>
      </div>

      <!-- Customer -->
      <div
        v-if="
          warehouse.warehouseable.type === 'customers' && !hideWarehouseable
        "
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.CUSTOMER')} (*)`"
            :placeholder="$t('COMMON.CUSTOMER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
          >
            <customer-selector
              :customer="warehouse.warehouseable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="warehouse.organization.id"
              @customerChanged="
                (customerId) => {
                  warehouse.warehouseable.id = customerId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.warehouseable" />
        </div>
      </div>

      <!-- Company name -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
            v-model="warehouse.company_name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.address" />
        </div>
      </div>

      <!-- Excerpt -->
      <div class="row">
        <div class="col">
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="warehouse.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </div>

      <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{
            warehouse.id
              ? $t("WAREHOUSES.EDIT_WAREHOUSE")
              : $t("WAREHOUSES.ADD_WAREHOUSE")
          }}
        </base-button>
      </div>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    CustomerSelector,
    SupplierSelector,
    HtmlEditor,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: [
    "warehouseData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideWarehouseable",
    "hideSubmit",
  ],

  data() {
    let warehouseData = { ...this.warehouseData };
    warehouseData = this.$fillUserOrganizationData(warehouseData);
    return {
      warehouse: warehouseData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let warehouseData = cloneDeep(this.warehouse);
      warehouseData = this.$fillUserOrganizationData(warehouseData);
      this.$emit("warehouseSubmitted", warehouseData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    warehouseData(warehouseData) {
      if (warehouseData) {
        this.warehouse = {
          ...this.warehouse,
          ...cloneDeep(warehouseData),
        };
      }
    },
  },
};
</script>
