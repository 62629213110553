var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.warehouse.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.warehouse.id},on:{"organizationChanged":function (organizationId) {
          _vm.warehouse.organization.id = organizationId;
          _vm.warehouse.warehouseable.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.warehouse.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.warehouse.organization.id},on:{"locationsChanged":function (locations) {
          _vm.warehouse.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.warehouse.organization.id && !_vm.hideWarehouseable)?_c('base-input',{attrs:{"label":((_vm.$t('WAREHOUSES.WAREHOUSE_TYPE')) + " (*)"),"placeholder":_vm.$t('WAREHOUSES.WAREHOUSE_TYPE')}},[_c('el-select',{attrs:{"name":("" + (_vm.$t('WAREHOUSES.WAREHOUSE_TYPE'))),"placeholder":_vm.$t('WAREHOUSES.WAREHOUSE_TYPE'),"disabled":!!_vm.warehouse.id},on:{"onchange":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.warehouse.warehouseable.type),callback:function ($$v) {_vm.$set(_vm.warehouse.warehouseable, "type", $$v)},expression:"warehouse.warehouseable.type"}},[_c('el-option',{attrs:{"value":"customers","label":_vm.$t("COMMON.CUSTOMER")}}),_c('el-option',{attrs:{"value":"suppliers","label":_vm.$t("COMMON.SUPPLIER")}})],1)],1):_vm._e(),(_vm.warehouse.warehouseable.type)?_c('div',[(
        _vm.warehouse.warehouseable.type === 'suppliers' && !_vm.hideWarehouseable
      )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SUPPLIERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.SUPPLIER')) + " (*)"),"placeholder":_vm.$t('COMMON.SUPPLIER')}},[_c('supplier-selector',{attrs:{"supplier":_vm.warehouse.warehouseable.id,"filterable":true,"showAll":false,"filterOrganization":_vm.warehouse.organization.id},on:{"supplierChanged":function (supplierId) {
                _vm.warehouse.warehouseable.id = supplierId;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.warehouseable}})],1)]):_vm._e(),(
        _vm.warehouse.warehouseable.type === 'customers' && !_vm.hideWarehouseable
      )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CUSTOMERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.CUSTOMER')) + " (*)"),"placeholder":_vm.$t('COMMON.CUSTOMER')}},[_c('customer-selector',{attrs:{"customer":_vm.warehouse.warehouseable.id,"filterable":true,"showAll":false,"filterOrganization":_vm.warehouse.organization.id},on:{"customerChanged":function (customerId) {
                _vm.warehouse.warehouseable.id = customerId;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.warehouseable}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.COMPANY_NAME')) + " (*)")},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.warehouse.company_name),callback:function ($$v) {_vm.$set(_vm.warehouse, "company_name", $$v)},expression:"warehouse.company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.warehouse.excerpt),callback:function ($$v) {_vm.$set(_vm.warehouse, "excerpt", $$v)},expression:"warehouse.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.warehouse.id ? _vm.$t("WAREHOUSES.EDIT_WAREHOUSE") : _vm.$t("WAREHOUSES.ADD_WAREHOUSE"))+" ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }